export const images = [
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/16_PeritoMoreno_Argentina_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/15_Chiloe_Chile_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/14_Manhattan_Newyork_Northamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/13_Iguazu_Argentina_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/12_LagoAtitlan_Guatemala_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/11_Mojave_California_Northamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/10_Comuna13_Colombia_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/09_ElMedano_Canarias_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/08_BocaChica_RD_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/07_Tenerife_Canarias_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/06_SenhorDaPedra_Portugal_Europe.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/05_AbuDhabi_UAE_MiddleEast.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/04_Tulum_Mexico_Latinoamerica.jpeg',
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/03_MachuPicchu_Peru_Latinoamerica.jpeg',
  `https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/02_Bar'am_Palestine_MiddleEast.jpeg`,
  'https://sumcho-dot-com-images.s3-eu-west-1.amazonaws.com/images/01_ValleDelCocora_Colombia_Latinoamerica.jpeg'
];